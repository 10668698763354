import React from "react";
import "./Gallery.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import imgOne from "../../assets/servicePage/gallery/1.png";
import imgTwo from "../../assets/servicePage/gallery/2.png";
import imgThree from "../../assets/servicePage/gallery/3.png";
import imgFour from "../../assets/servicePage/gallery/4.png";
import imgFive from "../../assets/servicePage/gallery/5.png";
import imgSix from "../../assets/servicePage/gallery/6.png";

// Sample video imports
import videoOne from "../../assets/servicePage/gallery/video1.mp4";
import videoTwo from "../../assets/servicePage/gallery/video2.mp4";

const Gallery = () => {
  const mediaItems = [
    { type: "image", src: imgOne, alt: "Gallery Image 1" },
    { type: "image", src: imgTwo, alt: "Gallery Image 2" },
    { type: "video", src: videoOne, alt: "Gallery Video 1" },
    { type: "image", src: imgThree, alt: "Gallery Image 3" },
    { type: "video", src: videoTwo, alt: "Gallery Video 2" },
    { type: "image", src: imgFour, alt: "Gallery Image 4" },
    { type: "image", src: imgFive, alt: "Gallery Image 5" },
    { type: "image", src: imgSix, alt: "Gallery Image 6" },
  ];

  return (
    <section
      className="gallery-section pt-100 pb-70"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <div className="container">
        <SectionTitle
          subTitle=""
          title="Some proof about our Products for you"
          description="We provide certified documentation and third-party verification to guarantee the authenticity and quality of our products."
        />
        <div className="row">
          {mediaItems.map((item, index) => (
            <div
              key={index}
              className={`col-md-${
                item.type === "video" ? "12" : "6"
              } col-sm-12`}
            >
              <div className="gallery-img">
                {item.type === "image" ? (
                  <img src={item.src} alt={item.alt} />
                ) : (
                  <video controls>
                    <source src={item.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Gallery;
