import React from "react";
import "./Appointment.scss";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import { AiFillHome } from "react-icons/ai";
import ContactForm from "../../components/ContactForm/ContactForm";

const Appointment = () => {
  const mapSrc =
    "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d15955.557238923499!2d36.7482880816785!3d-1.2364593271733222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1snew%20kitisuru%20kusini%20lane%20!5e0!3m2!1sen!2ske!4v1725398305786!5m2!1sen!2ske";

  return (
    <section
      className="appointment-section pb-70"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-6">
            <div className="google-map">
              <iframe
                title="map"
                src={mapSrc}
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>

              <div className="location-name">
                <AiFillHome />
                <p>New Kitisuru, Kusini Lane</p>
              </div>
            </div>
          </div>
          <div className="col-lg-7 col-md-6">
            <div className="appointment-form-area">
              <SectionTitle
                subTitle="CONTACT US"
                title="At Brand Mining, we take pride in delivering exceptional care."
                description="Experience exceptional service and quality with Brand Mining Ltd, where your satisfaction is our top priority. Let us make your next purchase a rewarding experience!"
              />

              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Appointment;
