import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import priorityImg from '../../assets/about/priority.png';
import './Priority.scss';

const Priority = () => {
    return (
      <section
        className="priority-section emergency-section"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <div className="priority-img">
                <img src={priorityImg} alt="Emergency" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="priority-text">
                <SectionTitle
                  subTitle="OUR PRIORITY"
                  title="Our clients are our priority"
                  description="At Brand Mining Ltd, our clients come first. We are dedicated to delivering exceptional service, ensuring that each client receives personalized attention and a seamless experience. Your success is our priority..."
                />

                <div className="theme-btn">
                  <Link to="/">See More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
};

export default Priority;