import React from "react";
import "./Banner.scss";
import bannerImage from "../../assets/banner/download.jpg"; // Replace with your actual image path

const Banner = () => {
  return (
    <section className="banner">
      <div className="banner-content">
        <h1>Welcome to Brand Mining Ltd</h1>
        {/* <p>
          Discover our top-rated minerals and services with unmatched quality
          and reliability.
        </p> */}
        <a href="/contact" className="btn-primary">
          Get in Touch
        </a>
      </div>
    </section>
  );
};

export default Banner;
