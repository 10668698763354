import React, { useState, useEffect } from "react";
import axios from "axios";
import Navbar from "../components/Navbar/Navbar";
import PageTitle from "../components/PageTitle/PageTitle";
import Footer from "../sections/Footer/Footer";
import BlogCard from "../components/BlogCard/BlogCard";

const BlogsPage = () => {
  const url = "http://localhost:8000/api/events";
  const [error, setError] = useState(null);
  const [items, setItems] = useState([]);

  useEffect(() => {
    axios
      .get(url)
      .then((response) => {
        console.log("API Response:", response.data); // For debugging
        setItems(response.data.data || []); // Set empty array if data is not available
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        setError(err);
      });
  }, [url]);

  if (error) {
    return <div>Error: {error.message}</div>; // Error handling
  }

  return (
    <>
      <Navbar />
      <PageTitle />
      <section className="pb-70" data-aos="fade-up" data-aos-duration="2000">
        <div className="container">
          <div className="row">
            {items.length > 0 ? (
              items.map((blog) => <BlogCard key={blog.ID} blog={blog} />)
            ) : (
              <div>No blogs available.</div> // Handle empty data
            )}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default BlogsPage;
