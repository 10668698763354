import React, { useState } from "react";
import "./BlogCard.scss";

// Function to strip HTML tags from a string
const stripHtmlTags = (str) => {
  return str.replace(/<\/?[^>]+(>|$)/g, "");
};

const BlogCard = ({ blog }) => {
  console.log(blog); // Log the blog data to inspect it

  // Destructure the blog object with keys having colons
  const ID = blog.ID;
  const Title = blog["Title:"] || "No Title";
  const Picture = blog.Picture || "";
  const Content = stripHtmlTags(blog["Content:"] || "No Content");

  // Assuming `Picture` is a relative URL; if not, adjust accordingly
  const imageUrl = Picture;

  // State to manage read more/less functionality
  const [isExpanded, setIsExpanded] = useState(false);

  const handleReadMoreToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="col-lg-4 col-md-6 col-sm-6">
      <div className="blog-card">
        <div className="blog-img">
          <img src={imageUrl} alt={Title} />
          <div className="date">{/* Add your dynamic date logic here */}</div>
        </div>
        <div className="blog-text">
          <h4>{Title}</h4>
          <p>
            {isExpanded
              ? Content
              : Content.length > 100
              ? `${Content.slice(0, 100)}...`
              : Content}
          </p>
          {Content.length > 100 && (
            <button onClick={handleReadMoreToggle} className="read-more">
              {isExpanded ? "Read Less" : "Read More"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
