import React from "react";
import "./Testimonial.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAward, faCheckCircle, faHandsHelping } from "@fortawesome/free-solid-svg-icons";

const WhatWeStandFor = () => {
  const stands = [
    {
      title: "Standard",
      description:
        "Standing tall on the pillars of innovation and precision, we proudly set the Standard. With a relentless pursuit of excellence, we redefine industry norms, ensuring that our product not only meets but surpasses the benchmarks of quality and sophistication.",
      icon: faAward,
    },
    {
      title: "Quality",
      description:
        "At our essence, we stand for Quality – a commitment to excellence that runs deep in every facet of our product. Immerse yourself in the assurance that precision, craftsmanship, and uncompromising standards define our dedication to delivering the finest.",
      icon: faCheckCircle,
    },
    {
      title: "Support",
      description:
        "What sets us apart is the unwavering Support we offer. Beyond transactions, we provide a guiding hand, expertise, and genuine care throughout your journey with us. We have extended our support to the community by establishing an orphanage.",
      icon: faHandsHelping,
    },
  ];

  return (
    <section className="what-we-stand-for">
      <div className="container">
        <h2 className="section-title">What We Stand For</h2>
        <div className="stands">
          {stands.map((stand, index) => (
            <div key={index} className="stand-item">
              <FontAwesomeIcon icon={stand.icon} className="stand-icon" />
              <h3 className="stand-title">{stand.title}</h3>
              <p className="stand-description">{stand.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhatWeStandFor;
