import React from "react";
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import imgOne from "../../assets/servicePage/gallery/8.png";
import imgTwo from "../../assets/servicePage/gallery/2.png";
import imgThree from "../../assets/servicePage/gallery/3.png";
import imgFour from "../../assets/servicePage/gallery/4.png";
import imgFive from "../../assets/servicePage/gallery/5.png";
import imgNine from "../../assets/servicePage/gallery/6.png";

// Sample video imports
import videoThree from "../../assets/servicePage/gallery/video3.mp4";
import videoFour from "../../assets/servicePage/gallery/video4.mp4";
import videoFive from "../../assets/servicePage/gallery/video5.mp4";
import videoSix from "../../assets/servicePage/gallery/video6.mp4";
import videoSeven from "../../assets/servicePage/gallery/video7.mp4";
import videoEight from "../../assets/servicePage/gallery/video8.mp4";
import videoNine from "../../assets/servicePage/gallery/video9.mp4";
import videoTen from "../../assets/servicePage/gallery/video10.mp4";
import videoEleven from "../../assets/servicePage/gallery/video11.mp4";
import videoTwelve from "../../assets/servicePage/gallery/video12.mp4";
import videoThirteen from "../../assets/servicePage/gallery/video13.mp4";

const Products = () => {
  const mediaItems = [
    { type: "image", src: imgOne, alt: "Gallery Image 1" },
    { type: "image", src: imgTwo, alt: "Gallery Image 2" },
    { type: "video", src: videoThree, alt: "Gallery Video 1" },
    { type: "image", src: imgNine, alt: "Gallery Image 9" },
    { type: "video", src: videoFour, alt: "Gallery Video 2" },
    { type: "video", src: videoFive, alt: "Gallery Video 2" },
    { type: "video", src: videoSix, alt: "Gallery Video 2" },
    { type: "video", src: videoSeven, alt: "Gallery Video 2" },
    { type: "video", src: videoEight, alt: "Gallery Video 2" },
    { type: "video", src: videoNine, alt: "Gallery Video 2" },
    { type: "video", src: videoTen, alt: "Gallery Video 10" },
    { type: "video", src: videoEleven, alt: "Gallery Video 11" },
    { type: "video", src: videoTwelve, alt: "Gallery Video 12" },
    { type: "video", src: videoThirteen, alt: "Gallery Video 13" },
  ];

  return (
    <section
      className="gallery-section pt-100 pb-70"
      data-aos="fade-up"
      data-aos-duration="2000"
    >
      <div className="container">
        
        <div className="row">
          {mediaItems.map((item, index) => (
            <div
              key={index}
              className={`col-md-${
                item.type === "video" ? "12" : "6"
              } col-sm-12`}
            >
              <div className="gallery-img">
                {item.type === "image" ? (
                  <img src={item.src} alt={item.alt} />
                ) : (
                  <video controls>
                    <source src={item.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Products;
